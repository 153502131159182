@import '../../styles/variables';

.TabsVertical {
  flex-direction: column;
  grid-row-gap: 15px;
  align-items: baseline;
}

.Tabs-item {
  font-weight: 500;
  color: #000;
  align-items: flex-start;
}

.Tabs-item {
  font-size: 14px;
  font-weight: 900;
  color: #9e9e9e;
  cursor: pointer;
}

.Tabs-item--active {
  color: $primaryColor;
  font-weight: 900;
}

.Tabs-item-outline {
  padding: 8px 18px;
  cursor: pointer;
  border-radius: 100px;
  color: #132026;
  font-size: 14px;
  line-height: 21px;
  background-color: $lightGray;
  border: 2px solid $lightGray;
}

.Tabs-item--active-outline {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  padding: 8px 18px;
  border: 2px solid $primaryColor;
  border-radius: 100px;

  color: $primaryColor;
  background-color: white;
}

.Tabs-item--bordered {
  background-color: #f5f5f5;
  border: 2px solid #f5f5f5;
  color: #000000;
  border-radius: 20px;
  padding: 7px 15px;
}

.Tabs-item--active.Tabs-item--bordered {
  background-color: #ffffff;
  border-color: #cba977;
  color: #cba977;
  font-weight: 600;
}
