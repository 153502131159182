$primaryColor: #cba977;
$secondaryColor: #fff8f2;
$lightGray: #f5f5f5;
$darkGray: #8F8982;
$gray: #bebebe;
$red: #ff1616;
$green: #00BC40;

$primaryColorHover: #C6A068;
$primaryColorActive: #BC965E;
