// .partitionedCodeField {
//   padding-left: 5px;
//   letter-spacing: 37px;
//   border: none;
//   background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
//   background-position: bottom;
//   background-size: 60px 1px;
//   background-repeat: repeat-x;
//   background-position-x: 35px;
//   width: 240px;
//   min-width: 240px;
//   font-size: 40px;

// }

.partitionedCodeField {
  padding-left: 10px;
  letter-spacing: 30px;
  border: none;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  font-size: 35px;
  outline: none;
}

.divInner {
  left: 0;
  position: sticky;
}

